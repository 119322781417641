<template>
  <DefaultLayout>
    <template v-slot:toolbar>
      <v-btn icon @click="$router.back()">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>

        <v-toolbar-title>Teman-Teman</v-toolbar-title>
    </template>

    <v-container>
      <img-cover :src="require('@/assets/img/students.jpg')"></img-cover>

      <div class="text-h6 ml-4 mb-2 mt-6">
        <v-icon color="blue">{{ $store.state.pageIcon }}</v-icon>
        {{ $store.state.pageTitle }}
      </div>
      <!-- <v-divider class="mb-4"></v-divider> -->

      <v-tabs v-model="tab" background-color="transparent">
        <v-tab>
          <span class="mr-1">Teman Sekelas</span><v-chip>{{ students.my_class.length }}</v-chip>
        </v-tab>
        <v-tab>
          <span class="mr-1">Semua Kelas</span><v-chip>{{ students.all.length }}</v-chip>
        </v-tab>
      </v-tabs>

      <v-divider></v-divider>

      <template v-slot:placeholder>
        <v-row class="fill-height ma-0" align="center" justify="center">
          <v-progress-circular
            indeterminate
            color="grey lighten-5"
          ></v-progress-circular>
        </v-row>
      </template>

      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-list dense height="100%" width="100%" class="mx-0">
            <v-skeleton-loader
              v-bind="attrs"
              v-if="loading"
              type="list-item-avatar-two-line,list-item-avatar-two-line,list-item-avatar-two-line,list-item-avatar-two-line,list-item-avatar-two-line,list-item-avatar-two-line"
            ></v-skeleton-loader>
            <v-list-item-group v-model="selectedItem" color="primary">
              <v-list-item
                v-for="(student, i) in students.my_class"
                :key="i"
                @click="viewInfo(student.id)"
                two-line
              >
                <v-list-item-avatar>
                  <v-img :src="student.photo"></v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title
                    v-text="student.firstname + ' ' + student.lastname"
                  ></v-list-item-title>
                  <v-list-item-subtitle
                    v-text="student.address"
                  ></v-list-item-subtitle>
                  <v-list-item-subtitle
                    v-text="(student.classname)"
                  ></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>

          <v-alert
            color="warning"
            v-if="loading == false && students.my_class.length == 0"
          >
            <span>Oppss.. Belum ada data.</span></v-alert
          >
        </v-tab-item>
        <v-tab-item>

          <v-list dense height="100%" width="100%" class="mx-0">
            <v-skeleton-loader
              v-bind="attrs"
              v-if="loading"
              type="list-item-avatar-two-line,list-item-avatar-two-line,list-item-avatar-two-line,list-item-avatar-two-line,list-item-avatar-two-line,list-item-avatar-two-line"
            ></v-skeleton-loader>
            <v-list-item-group v-model="selectedItem" color="primary">
              <v-list-item
                v-for="(student, i) in students.all"
                :key="i"
                @click="viewInfo(student.id)"
                two-line
              >
                <v-list-item-avatar>
                  <v-img :src="student.photo"></v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title
                    v-text="student.fullname"
                  ></v-list-item-title>
                  <v-list-item-subtitle
                    v-text="(student.address)"
                  ></v-list-item-subtitle>
                  <v-list-item-subtitle
                    v-text="(student.classname)"
                  ></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>

          <v-alert
            color="warning"
            v-if="loading == false && students.all.length == 0"
          >
            <span>Oppss.. Belum ada data.</span></v-alert
          >
        </v-tab-item>
      </v-tabs-items>
    </v-container>
  </DefaultLayout>
</template>

<script>
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import ImgCover from '@/components/ImgCover.vue';

export default {
  name: "MyFriends",
  components: { DefaultLayout, ImgCover, },

  data: () => ({
    tab: null,
    selectedItem: null,
    students: {
      my_class: [],
      all: [],
    },
    all: [],
    loading: null,
    attrs: {
      class: "mb-6",
      boilerplate: true,
      elevation: 2,
    },
  }),

  methods: {
    getList() {
      this.loading = true;
      this.$axios.get("classroom/friends").then((res) => {
        this.$store.state.showLoadingDialog = false;
        if (res.data.status == "success") {
          this.students = res.data.data;
        } else {
          this.students = [];
        }
        this.loading = false;
      });
    },
    viewInfo(id) {
      this.$router.push("/myfriends/" + id + "/info");
    },
  },

  mounted() {
    this.getList();
    this.setPageTitle("Teman Sekelas", "mdi-human");
  },
};
</script>